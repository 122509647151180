/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// Wrap element with Swiper elements
var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
	var $element = $(element);
	$element.children(childrenSelector).addClass('swiper-slide');
	$element.wrapInner('<div class="swiper-wrapper" />');

	if (pagination) {
		$element.append('<div class="swiper-pagination" />');
	}

	if (navigation) {
		$element.append('<a class="swiper-button-prev"><i class="icon icon-chevron-left"></i></a>');
		$element.append('<a class="swiper-button-next"><i class="icon icon-chevron-right"></i></a>');
	}
};

// file uploads
var uploadInputs = document.querySelectorAll('.fileupload input');
Array.prototype.forEach.call(uploadInputs, function (input) {
	var label = input.nextElementSibling,
		labelVal = label.innerHTML;

	input.addEventListener('change', function (e) {
		var fileName = '';
		if (this.files && this.files.length > 1)
			fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
		else
			fileName = e.target.value.split('\\').pop();

		if (fileName)
			label.setAttribute('data-caption', fileName);
		else
			label.setAttribute('data-caption', labelVal);
	});
});

$(async function () {

	// Block survery
	$('.blocksurvey-share.button').each(function () {
		//$(this.click($(document.body.classList.add("survey-open"))));
		
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler');
		var responsiveNav = $('.responsive-nav');

		responsiveNav.toggleClass('-open');
		toggler.toggleClass('is-active');
	}

	// Close dropdown on click
	document.querySelectorAll('dl.dropdown-toggle > dt').forEach(el => {
		el.addEventListener('click', () => {
			el.parentElement.classList.add('clicked');
			el.parentElement.classList.toggle('open');
		});

		el.addEventListener('blur', () => {
			// Timeout is required for iOS
			setTimeout(() => {
				el.parentElement.classList.remove('clicked');
				el.parentElement.classList.remove('open');
			}, 0);
		});

		// Prevent mousedown in next sibling (dd) to prevent click event to bubble to blur
		el.nextElementSibling.addEventListener('mousedown', e => {
			e.preventDefault();
		});

		el.addEventListener('keydown', e => {
			// Space and Enter
			if (e.keyCode === 13 || e.keyCode === 32) {
				el.click();
				e.preventDefault();
			}
		});
	});

	// Higlighted slider
	$('.highlighted-slider .swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		new Swiper(this, {
			grabCursor: true,
			loop: true,
			autoplay: {
				delay: 10000
			},
			speed: 1500,
			spaceBetween: 50,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
	});

	// Image slider
	$('.image-slider .swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		new Swiper(this, {
			grabCursor: true,
			loop: false,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			spaceBetween: 50,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		});
	});

	// Testimonial gallery
	$('.testimonial-gallery').each(async function () {
		var $this = $(this),
			slides = $this.find('.slides');

		if (slides.children('.slide').length <= 1) {
			// Need more than 1 image for an image gallery
			return;
		}

		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		var thumbs = $this.find('.thumbs'),
			thumbsSwiper = null;

		if (thumbs.length) {
			wrapSwiper(thumbs, '.thumb', false, true);

			thumbsSwiper = new Swiper(thumbs, {
				spaceBetween: 20, // 1rem
				slidesPerView: 2,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				breakpointsInverse: true,
				breakpoints: {
					360: {
						slidesPerView: 3
					},
					600: {
						slidesPerView: 4
					},
					768: {
						slidesPerView: 6
					},
					992: {
						slidesPerView: 8
					}
				}
			});
		}

		wrapSwiper(slides, '.slide', false, false);

		var slidesSwiper = new Swiper(slides, {
			spaceBetween: 50,
			speed: 500,
			loop: true,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			autoplay: true,
			thumbs: {
				swiper: thumbsSwiper
			}
		});
	});

	// FAQ 
	var dropdowns = $('.accordion, .downloads-accordion');
	if (dropdowns.length) {
		await import("../../node_modules/responsive-bp/src/js/responsive.core.js");
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");
	}

	// Lightgallery
	async function loadLightGallery() {
		await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
		await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

		lightGallery(document.getElementsByTagName("body")[0], {
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: true,
			actualSize: false,
			hash: false,
			videoMaxWidth: '1920px'
		});
	}

	var elementsWithModal = document.querySelectorAll('[rel=lightbox]');
	if (elementsWithModal.length) {
		loadLightGallery();
	}

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 250);

			return false;
		}
	});

	$('#filter').each(function () {
		var form = $(this);
		var submit = form.find('button');
		var input = form.find('input');

		submit.hide();

		input.each(function () {

			$(this).on('change', function () {
				form.submit();
			});
		});
	});

	// Modal overview
	var modalContainers = document.querySelectorAll('.modal-overview');
	if (modalContainers.length) {

		modalContainers.forEach(el => {
			var culture = document.documentElement.lang;

			el.addEventListener('click', event => {
				var modalItem = event.target.closest('.modal-item');
				if (modalItem) {
					getModal('/umbraco/Surface/Modal/Info?id=' + modalItem.id + '&type=' + modalItem.dataset.type + "&culture=" + culture, el);
				}
			});
		});
	}

	function getModal(fetchUrl, overview) {

		fetch(fetchUrl, {
			method: 'get',
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		})
			.then(response => response.text())
			.then(data => {
				var parser = new DOMParser();
				var newModal = parser.parseFromString(data, 'text/html').querySelector('.modal');
				var modalContainer = document.querySelector('.modal-container');

				if (modalContainer) {
					var oldModal = modalContainer.querySelector('.modal');
					oldModal.classList.remove('fade-in')
					setTimeout(function () { oldModal.remove(); modalContainer.appendChild(newModal); }, 500)
					setTimeout(function () { newModal.classList.add('fade-in'); }, 550)
				}
				else {
					modalContainer = document.createElement('div');
					modalContainer.classList.add('modal-container');
					modalContainer.appendChild(newModal);
					document.body.appendChild(modalContainer);

					setTimeout(function () { modalContainer.classList.add('fade-in') }, 50)
					setTimeout(function () { newModal.classList.add('fade-in') }, 500)

					modalContainer.addEventListener('click', event => {
						if (event.target == modalContainer) {
							closeModal(event, modalContainer);
						}

						if (event.target.className.includes('modal__close')) {
							closeModal(event, modalContainer);
						}
						if (event.target.className == 'modal__prev') {
							getSiblingModal(event, modalContainer, overview, false);
						}
						if (event.target.className == 'modal__next') {
							getSiblingModal(event, modalContainer, overview, true);
						}
					});

					document.addEventListener('keydown', event => {
						if (event.keyCode === 27) {
							closeModal(event, modalContainer);
						}
					});
				}
			});
	}

	function closeModal(e, modalContainer) {
		var modal = modalContainer.querySelector('.modal');
		modal.classList.remove('fade-in')
		setTimeout(function () { modalContainer.classList.remove('fade-in') }, 500)
		setTimeout(function () { modalContainer.remove() }, 1000)
	}

	function getSiblingModal(e, modalContainer, overview, getNext) {
		var modal = modalContainer.querySelector('.modal');
		var id = modal.dataset.modalId;
		var modalItems = overview.querySelectorAll('.modal-item');
		var modalItemsCount = modalItems.length;
		var currentModelItemIndex = 0;
		var nextModalItemToShow = null;

		for (var i = 0; i < modalItemsCount; i++) {
			if (modalItems[i].id == id) {
				currentModelItemIndex = i;
			}
		}

		if (getNext) {
			nextModalItemToShow = modalItems[currentModelItemIndex + 1];
			if (nextModalItemToShow == null) {
				nextModalItemToShow = modalItems[0];
			}
		}
		else {
			nextModalItemToShow = modalItems[currentModelItemIndex - 1];
			if (nextModalItemToShow == null) {
				nextModalItemToShow = modalItems[(modalItemsCount - 1)];
			}
		}

		if (nextModalItemToShow !== null) {
			getModal('/umbraco/Surface/Modal/Info?overviewId=' + overview.id + '&id=' + nextModalItemToShow.id + '&culture=' + document.documentElement.lang, overview);
		}
	}
});

var surveyOverlay = document.querySelector('.overlay');
if (surveyOverlay) {

	var loginBtn = document.querySelector('.login-btn');
	if (loginBtn) {
		loginBtn.addEventListener("click", function (e) {
			surveyOverlay.classList.remove("invisible");
		});
	}
	var closeOverlay = document.querySelector('.close-btn');
	if (closeOverlay) {
		closeOverlay.addEventListener("click", function (e) {
			surveyOverlay.classList.add("invisible");
		});
	}

	document.addEventListener("click", function (e) {
		if (e.target === surveyOverlay) {
			surveyOverlay.classList.add("invisible");
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && !(surveyOverlay.classList.contains("invisible"))) {
			surveyOverlay.classList.add("invisible");
		}
	});
}